<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-descriptions size="large" border>
      <el-descriptions-item>
        <template #label> Mês de referência: </template>
        <el-date-picker v-model="dateReference" type="month" size="medium">
        </el-date-picker>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions size="large" border>
      <el-descriptions-item>
        <template #label> Nota fiscal: </template>
        <el-row type="flex" justify="end">
          <el-radio-group
            v-model="searchWithInvoiceMode"
            size="large"
            @change="fetchOrders"
          >
            <el-radio-button label="Apenas com nota" />
            <el-radio-button label="Apenas sem nota" />
            <el-radio-button label="Indiferente" />
          </el-radio-group>
        </el-row>
      </el-descriptions-item>
    </el-descriptions>
    <el-row type="flex" :gutter="8">
      <el-col :md="24" :xs="24">
        <el-card shadow="always">
          <template #header>
            <el-row type="flex" justify="between">
              <el-col :md="8">
                <h4 class="mb-0">Peso vendido por produto</h4>
                <h6 class="text-muted">
                  Gráfico que mostra participação por peso de cada produto no
                  total vendido
                </h6>
              </el-col>
              <el-col :md="16" class="mt-2">
                <el-select
                  placeholder="Busque pelo nome, CPF ou CNPJ"
                  v-model="selectedProductCategory"
                  value-key="uid"
                  size="medium"
                >
                  <template #prefix> Categoria de Produto </template>
                  <el-option
                    v-for="item in Categories"
                    :key="item.uid"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </template>
          <VueApexCharts
            type="donut"
            style="max-height: 500px"
            v-if="ProductSellSeries?.length"
            :options="getDonutOptions()"
            :series="ProductSellSeries"
          ></VueApexCharts>
        </el-card>
      </el-col>
      <el-col :md="12" :xs="24"> </el-col>
    </el-row>
  </el-card>
</template>

<script>
import OrderService from "../services/orders";
import { weightFormatter, currencyFormatter } from "../utils/formatters";
import ProductCategoryService from "../services/categories";
import VueApexCharts from "vue3-apexcharts";
export default {
  name: "OrderResumeReport",
  data: () => ({
    isLoading: false,
    weightFormatter,
    currencyFormatter,
    dateReference: new Date(),
    orders: null,
    categories: null,
    searchWithInvoiceMode: "Apenas com nota",
    selectedProductCategory: null,
  }),
  components: {
    VueApexCharts,
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    Categories() {
      return this.categories || [];
    },
    ProductSellSeries() {
      const productSells = this.groupSellsByProducts();

      if (productSells)
        return Object.entries(productSells).map((sells) =>
          sells[1].reduce((total, { weight }) => total + Number(weight), 0)
        );
      return [];
    },
    StartOfMonth() {
      const startOfMonth = new Date(this.dateReference);
      startOfMonth.setDate(1);
      startOfMonth.setHours(0, 0, 0, 0);

      return startOfMonth;
    },
    EndOfMonth() {
      const endOfMonth = new Date(this.dateReference);
      endOfMonth.setMonth(endOfMonth.getMonth() + 1);
      endOfMonth.setDate(0);
      endOfMonth.setHours(23, 59, 59, 999);

      return endOfMonth;
    },
  },
  watch: {
    dateReference() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      const { categories } = await ProductCategoryService().index();

      if (categories) {
        this.categories = categories;
        this.selectedProductCategory = categories[0];
      }
    },
    groupSellsByProducts() {
      return this.orders
        ?.map(({ products }) => products)
        ?.flat()
        ?.filter(
          ({ product }) =>
            product.category.uid === this.selectedProductCategory.uid
        )
        ?.reduce((group, sell) => {
          const name = sell.product?.name;

          if (name in group) group[name].push(sell);
          else group[name] = [sell];
          return group;
        }, {});
    },
    getDonutOptions() {
      return {
        chart: {
          height: 500,
          type: "donut",
          zoom: {
            enabled: false,
          },
          locales: [
            {
              name: "pt-br",
              options: {
                months: [
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ],
                shortMonths: [
                  "Jan",
                  "Fev",
                  "Mar",
                  "Abr",
                  "Mai",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Set",
                  "Out",
                  "Nov",
                  "Dez",
                ],
                days: [
                  "Domingo",
                  "Segunda",
                  "Terça",
                  "Quarta",
                  "Quinta",
                  "Sexta",
                  "Sábado",
                ],
                shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                toolbar: {
                  exportToSVG: "Baixar SVG",
                  exportToPNG: "Baixar PNG",
                  exportToCSV: "Baixar CSV",
                  menu: "Menu",
                  selection: "Selecionar",
                  selectionZoom: "Selecionar Zoom",
                  zoomIn: "Aumentar",
                  zoomOut: "Diminuir",
                  pan: "Navegação",
                  reset: "Reiniciar Zoom",
                },
              },
            },
          ],
          defaultLocale: "pt-br",
        },
        //colors: ["#0d84ff"],
        dataLabels: {
          enabled: false,
        },
        labels: Object.keys(this.groupSellsByProducts()),
        series: this.ProductSellSeries,
        legend: {
          formatter: (label) => label,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Peso de cada produto vendido no período",
          align: "left",
        },
        /*grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 1,
          },
        },*/
        xaxis: {
          type: "numeric",
        },
        yaxis: {
          labels: {
            formatter: (v) => weightFormatter.format(Number(v) || 0) + " kg.",
          },
        },
      };
    },
    async fetchData() {
      this.fetchOrders();
    },
    shouldFetchWithInvoices() {
      return this.searchWithInvoiceMode === "Apenas com nota";
    },
    async fetchOrders() {
      this.isLoading = true;

      const filters = {
        include_product_category: true,
        delivered_at_start: this.StartOfMonth,
        delivered_at_end: this.EndOfMonth,
        status: "finalized,completed,paid,delivered",
      };

      if (this.searchWithInvoiceMode !== "Indiferente")
        filters.has_invoice = this.shouldFetchWithInvoices();

      const { orders } = await OrderService().index(filters);

      if (orders) {
        this.orders = orders;
      } else this.orders = null;

      this.isLoading = false;
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
